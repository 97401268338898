import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from "@angular/core";
import { takeUntil } from "rxjs/operators";
import { ItemBrowserComponent } from "../../../browsing/components/item-browser/item-browser.component";
import { DisplayMode } from "../../../core/models/display-mode";
import { DraggedItemType } from "../../../core/models/drag/dragged-item-type";
import { DroppableElement } from "../../../core/models/droppable-element";
import { IDragDropService } from "../../../core/services/i-drag-drop.service";
import { Dispatcher } from "../../../dispatcher";
import { EnvironmentSelector } from "../../../environment";
import { AppStatusActions } from "../../../environment/store/app-status/app-status.actions";
import { IS_ATTACHABLE } from "../../../meta/decorators/editable-type.decorator";
import { TypeDescriptor, TypeDescriptorAlias } from "../../../meta/models";
import { TypeProvider } from "../../../meta/services/type-provider";
import { isDefined } from "../../../ts-utils";
import { resolveComponentClassName } from "../../../ts-utils/helpers/component-display-name.helper";
import { ComponentTypeInfo } from "../../models/component-type-info";
import { BaseComponent } from "../base/base.component";
@Component({
  selector: "layout-builder-tab",
  templateUrl: "layout-builder-tab-content.component.html",
  styleUrls: ["./layout-builder-tab-content.component.scss"]
})
export class LayoutBuilderTabContentComponent extends ItemBrowserComponent implements OnInit {
  @Input() componentCategory: string;
  componentsTypeDescriptors: ComponentTypeInfo[];
  displayMode: string;
  @Output() collapseSidebar: EventEmitter<any> = new EventEmitter();

  constructor(
    private dispatcher: Dispatcher,
    dragDropService: IDragDropService,
    private typeProvider: TypeProvider,
    protected environmentSelector: EnvironmentSelector
  ) {
    super(dragDropService, environmentSelector);
  }

  ngOnInit() {
    this.subscribeToDisplayMode();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["componentCategory"]) {
      this.componentsTypeDescriptors = this.getComponentsOfCategory(this.componentCategory);
    }
  }

  dragStart(event, draggableItem: DroppableElement, rootPath: string = "") {
    this.collapseSidebar.emit();
    super.dragStart(event, draggableItem, rootPath);
    if (this.displayMode === DisplayMode.Tablet) {
      this.dispatcher.dispatch(AppStatusActions.collapseSidebar());
    }
  }

  dropOnTouchDevices(event) {
    super.dropOnTouchDevices(event);
    if (isDefined(this.selectedItem)) {
      this.displayMode === DisplayMode.Tablet
        ? this.dispatcher.dispatch(AppStatusActions.collapseSidebar())
        : this.dispatcher.dispatch(AppStatusActions.closeSidebar());
    }
  }

  private subscribeToDisplayMode(): void {
    this.environmentSelector
      .selectDisplayMode()
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((displayMode: string) => {
        this.displayMode = displayMode;
      });
  }

  private getAllAttachables(): TypeDescriptor[] {
    const types: TypeDescriptor[] = this.typeProvider.getTypes();
    const baseComponentType: TypeDescriptor = this.typeProvider.getTypeByConstructor(BaseComponent);

    const allAttachables: TypeDescriptor[] = types.filter((type: TypeDescriptor) => {
      const isAttachable =
        type.isInheritedFrom(baseComponentType) && Reflect.getMetadata(IS_ATTACHABLE, type);
      return isAttachable;
    });
    return allAttachables;
  }

  private getComponentsOfCategory(category: string): ComponentTypeInfo[] {
    const attachables: TypeDescriptor[] = this.getAllAttachables();
    const filteredAttachables: TypeDescriptor[] = attachables.filter((component: TypeDescriptor) =>
      component.aliases.some((alias) => alias.category === category)
    );

    return filteredAttachables
      .map((component: TypeDescriptor) => {
        const filteredAliases: TypeDescriptorAlias[] = component.aliases.filter(
          (alias: TypeDescriptorAlias) => alias.category === category
        );
        return filteredAliases.map((alias) => new ComponentTypeInfo(component, alias));
      })
      .flat();
  }

  getComponentClassName(componentInfo: ComponentTypeInfo): string {
    return resolveComponentClassName(componentInfo.alias.displayName, this.environmentSelector);
  }

  getIconFromComponentMetadata(component: ComponentTypeInfo) {
    return component.alias.iconGroup + " " + component.alias.icon;
  }

  getDragTarget(componentTypeName: ComponentTypeInfo) {
    return {
      type: DraggedItemType.Component,
      item: componentTypeName
    };
  }

  openHelp(draggableItem: ComponentTypeInfo): void {
    window.top?.open(draggableItem.alias.userHelp);
  }
}
