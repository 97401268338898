<div
  *ngIf="newTarget"
  class="property-sheet__container"
  [ngClass]="{
    'property-sheet__container--full-width': displayMode === DisplayMode.Mobile,
    'property-sheet--pinned': isPinned,
    'property-sheet--decreased-height': isToolbarOpened && isToolbarPinned && !isPinned
  }"
  [@.disabled]="shouldDisableAnimation"
  [@enterAnimation]
  [style.width.px]="width"
  (clickOutside)="clickOutside()"
>
  <div class="property-sheet__resize-wrapper" (mousedown)="onResizeStart($event)">
    <div class="property-sheet__resize-border"></div>
  </div>
  <div
    class="property-sheet__content"
    [ngClass]="{ 'property-sheet__content--disabled': isDisabled }"
  >
    <div class="property-sheet__header-buttons">
      <button
        *ngIf="displayMode === DisplayMode.Desktop"
        class="property-sheet__header-button"
        [ngClass]="{ 'property-sheet__header-button--pinned': isPinned }"
        (click)="onChangePinMode()"
        title="{{
          isPinned ? (localizer.buttons.Unpin | translate) : (localizer.buttons.Pin | translate)
        }}"
      >
        <i class="abb-icon abb-icon--small" [ngClass]="isPinned ? 'Pin' : 'Unpin'"></i>
      </button>
      <i
        class="sidebar__header-button abb-icon abb-icon--small Close"
        (click)="onClose()"
        tabIndex="0"
      ></i>
      <div
        class="property-sheet__toggle-wrapper"
        *ngIf="targetType.name === 'ComponentStateViewModel' && !isSearchingInProgress"
      >
        <label class="property-sheet__toggle-label" [ngClass]="{ 'advanced-mode': advancedMode }">{{
          localizer.propertySheet.AdvancedMode | translate
        }}</label>
        <toggle-switch [value]="advancedMode" (changeValue)="toggleAdvancedMode()"></toggle-switch>
      </div>
    </div>
    <div class="property-sheet__header-info">
      <span class="property-sheet__title"><span [innerHTML]="targetTitle | safe"></span></span>
      <span class="property-sheet__subtitle">{{ subtitle | translate }}</span>
    </div>
    <div
      class="property-sheet__body"
      [class.search-input]="isSearchingInProgress"
      *ngIf="propertiesToDisplay.size > 0"
    >
      <mat-tab-group
        #tabGroup
        [class.hidden-tab-header]="shouldHideTabHeader(propertiesToDisplay)"
        [(selectedIndex)]="activeTabIndex"
        (selectedTabChange)="selectedTabChange($event)"
        disablePagination="true"
        animationDuration="0ms"
        disableRipple="true"
      >
        <div *ngIf="!isSearchingInProgress; else searchTemplate">
          <mat-tab
            *ngFor="
              let propsByCategory of propertiesToDisplay | keyvalue : originalOrder;
              trackBy: sectionTabNames
            "
            [aria-labelledby]="
              !tabHasProperties(propsByCategory.key, propertiesToDisplay) ? 'hidden' : ''
            "
          >
            <ng-template mat-tab-label
              >{{ localizer.propertySheet[propsByCategory.key] | translate }}
            </ng-template>
            <div class="property-sheet__tab-wrapper">
              <property-sheet-page
                *ngIf="tabHasProperties(propsByCategory.key, propertiesToDisplay)"
                [targetInfo]="{ value: newTarget, type: targetType }"
                [sectionedProps]="propertiesToDisplay.get(propsByCategory.key)"
              ></property-sheet-page>
            </div>
          </mat-tab>
        </div>
        <ng-template #searchTemplate>
          <div>
            <mat-tab labelClass="property-sheet__search-tab-label">
              <ng-template mat-tab-label>
                <input
                  #searchInput
                  type="text"
                  class="browser__search-input-text property-sheet__search-input"
                  [value]="searchPattern"
                  title="{{ localizer.propertySheet.Search | translate }}"
                  (input)="searchPatternChanged$.next(searchInput.value)"
                />
                <button class="property-sheet__clear-button" (click)="clearSearchInput()">
                  <i class="abb-icon abb-icon--medium Close"></i>
                </button>
              </ng-template>
              <div
                *ngFor="let propsByCategory of propertiesMatchPattern | keyvalue : originalOrder"
              >
                <div *ngIf="tabHasProperties(propsByCategory.key, propertiesMatchPattern)">
                  <div class="property-sheet__tab-wrapper">
                    <property-sheet-page
                      [targetInfo]="{ value: newTarget, type: targetType }"
                      [sectionedProps]="propertiesMatchPattern.get(propsByCategory.key)"
                    ></property-sheet-page>
                  </div>
                </div>
              </div>
            </mat-tab>
          </div>
        </ng-template>
      </mat-tab-group>
      <button
        class="property-sheet__search-button"
        [class.element--hidden]="shouldHideTabHeader(propertiesToDisplay)"
        (click)="onChangeSearchMode()"
      >
        <i class="abb-icon abb-icon--medium Search"></i>
      </button>
    </div>
  </div>
</div>
