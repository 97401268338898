<div #arrayWrapper class="array-editor__wrapper">
  <div *ngIf="isGroupable; else elseBlock">
    <div
      *ngFor="
        let group of groups | sortAlphabetically : 'groups';
        let groupIndex = index;
        trackBy: trackGroupsById
      "
    >
      <div *ngIf="group.id !== ''">
        <mat-accordion *ngIf="enableGroups; else connectorsPerGroup">
          <mat-expansion-panel
            [ngClass]="{ 'dragged-over-target': draggedOverTargets[groupIndex] }"
            [expanded]="groups[groupIndex].isExpanded"
            (dragover)="onDragOver($event, groupIndex)"
            (dragleave)="onDragLeave($event, groupIndex)"
            (drop)="drop($event, group.id)"
          >
            <mat-expansion-panel-header
              (click)="toggleExpansionPanel(groupIndex)"
              [ngClass]="{
                'array-editor__group-with-invalid-id': invalidGroupIndex === groupIndex
              }"
            >
              <mat-panel-title (keydown)="$event.stopImmediatePropagation()">
                <span class="array-editor__group-header">
                  <i
                    class="abb-icon abb-icon--large"
                    [ngClass]="groups[groupIndex].isExpanded ? 'Folder_open' : 'Folder'"
                  ></i>
                  <input
                    type="text"
                    class="array-editor__group-header-input"
                    name="group-{{ groupIndex }}"
                    [value]="group.id"
                    (keyup)="groupNameObserver$.next({ event: $event, index: groupIndex })"
                    title="{{ translationService.propertySheet.GroupTooltip | translate }}"
                    [readonly]="invalidGroupIndex !== -1 && invalidGroupIndex !== groupIndex"
                  />
                </span>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <ng-container *ngTemplateOutlet="connectorsPerGroup"></ng-container>
          </mat-expansion-panel>
        </mat-accordion>
        <ng-template #connectorsPerGroup>
          <div
            *ngFor="let arrayItem of getGroupItemsByGroupId(group.id); let itemIndex = index"
            class="array-editor-dc"
          >
            <span
              class="item-drag-dots"
              draggable="true"
              (dragstart)="dragStart($event, arrayItem)"
              (dragend)="onDragEnd()"
              (drop)="drop($event, group.id, itemIndex)"
            >
              <i
                class="dashboard-icon icon-Drag-dots"
                title="{{ translationService.propertySheet.DragToReorder | translate }}"
              ></i>
            </span>
            <data-connector-array-item
              [dataConnector]="arrayItem"
              (onEdit)="editArrayItem($event, itemIndex)"
              (onRemove)="remove($event, itemIndex)"
            ></data-connector-array-item>
          </div>
        </ng-template>
      </div>
    </div>
    <div
      *ngIf="getGroupById('')"
      [ngClass]="{ 'array-editor__data-connectors': enableGroups }"
      (dragover)="onDragOver($event)"
      (drop)="drop($event, '')"
    >
      <div
        tabindex="-1"
        *ngFor="let arrayItem of getGroupItemsByGroupId(''); let itemIndex = index"
        class="array-editor-dc"
      >
        <span
          class="item-drag-dots"
          draggable="true"
          (dragstart)="dragStart($event, arrayItem)"
          (dragend)="onDragEnd()"
          (drop)="drop($event, '', itemIndex)"
        >
          <i
            class="dashboard-icon icon-Drag-dots"
            title="{{ translationService.propertySheet.DragToReorder | translate }}"
          ></i>
        </span>
        <data-connector-array-item
          (onEdit)="editArrayItem($event, itemIndex)"
          (onRemove)="remove($event, itemIndex)"
          [dataConnector]="arrayItem"
        ></data-connector-array-item>
      </div>
    </div>
    <div
      class="array-editor__drop-area"
      (dragover)="onDragOver($event)"
      (drop)="drop($event, '')"
    ></div>
  </div>
  <ng-template #elseBlock>
    <div
      #itemWrapper
      tabindex="-1"
      *ngFor="let arrayItem of value; let itemIndex = index; trackBy: trackItemsByIndex"
      class="array-editor__item"
      [ngClass]="{ 'dragged-over-target': draggedOverTargets[itemIndex] }"
    >
      <div class="array-editor__item-wrapper">
        <span
          class="item-drag-dots"
          draggable="true"
          (dragstart)="dragStart($event, arrayItem, itemIndex)"
          (dragend)="onDragEnd()"
          (drop)="drop($event, '', itemIndex)"
          (dragover)="onDragOver($event, itemIndex)"
          (dragleave)="onDragLeave($event, itemIndex)"
        >
          <i
            class="dashboard-icon icon-Drag-dots"
            title="{{ translationService.propertySheet.DragToReorder | translate }}"
          ></i>
        </span>
        <button
          *ngIf="typeDescriptor && !typeDescriptor.isPrimitive"
          class="array-editor__item-button expand-collapse-button"
          (click)="expandOrCollapseItem(arrayItem, itemIndex)"
          [disabled]="typeDescriptor && typeDescriptor.isPrimitive"
          title="{{
            (itemsInEditMode[itemIndex]
              ? translationService.buttons.Collapse
              : translationService.buttons.Expand
            ) | translate
          }}"
        >
          <i class="abb-icon" [ngClass]="itemsInEditMode[itemIndex] ? 'Down' : 'Right'"></i>
        </button>
        <input
          #itemInput
          focusVisualization
          [focusedElement]="itemWrapper"
          [isNested]="true"
          type="text"
          class="array-editor__title-input"
          [ngClass]="{
            'name--edit-mode': itemsInEditMode[itemIndex],
            'dragged-over-target': draggedOverTargets[itemIndex],
            'array-editor__title-input--invalid-input': invalidTitleInputs[itemIndex]
          }"
          [value]="resolveItemDisplayName(arrayItem)"
          (input)="itemTitleChanged$.next({ event: $event, index: itemIndex })"
          [readonly]="!canChangeItemTitle"
          [title]="resolveTooltipForTitleProperty()"
          [matAutocomplete]="autocompleteReferences[itemIndex]"
          [matAutocompleteDisabled]="!autocompleteReferences[itemIndex]"
        />
        <ng-container *ngIf="propertyInfo.descriptor.allowInterpolation">
          <autocomplete-interpolation
            #autocompleteInterpolation
            [value]="resolveItemDisplayName(arrayItem)"
            [ownerInfo]="parentInfo"
            (onValueChange)="changeNestedObjectTitle($event, itemIndex)"
          >
          </autocomplete-interpolation>
        </ng-container>
        <div class="array-editor__item-button-wrapper" *ngIf="validationFunction(arrayItem)">
          <i
            *ngIf="propertyInfo.descriptor.canBeHidden"
            class="array-editor__item-button hide-view-button abb-icon"
            [ngClass]="arrayItem.isHidden ? 'Hide' : 'View'"
            title="{{
              (arrayItem.isHidden
                ? translationService.buttons.Show
                : translationService.buttons.Hide
              ) | translate
            }}"
            (click)="hideOrShowItem(arrayItem, itemIndex)"
          ></i>
          <i
            *ngIf="value.length > propertyInfo.descriptor.defaultValue.length"
            class="array-editor__item-button delete-button abb-icon Trash"
            title="{{ translationService.buttons.Delete | translate }}"
            tabindex="-1"
            (click)="removeArrayItem(arrayItem, itemIndex, $event)"
          ></i>
        </div>
      </div>
      <div *ngIf="invalidTitleInputs[itemIndex]" class="editor-item__message">
        Value is invalid, it will not be saved.
      </div>
      <div
        class="array-editor__item-to-edit"
        *ngIf="itemsInEditMode[itemIndex]"
        [ngClass]="{ 'dragged-over-target': draggedOverTargets[itemIndex] }"
      >
        <array-editor-item
          [descriptor]="propertyInfo.descriptor"
          [value]="arrayItem"
          [itemTitle]="itemTitleProperty?.name"
          [itemOwner]="itemOwner"
          (updatePropertyValue)="editArrayItem($event, itemIndex)"
        ></array-editor-item>
      </div>
    </div>
  </ng-template>
</div>
<div class="array-editor__footer">
  <i
    class="array-editor__footer-button abb-icon abb-icon--large Plus_in_circle"
    title="{{ tooltip | translate }}"
    (click)="addArrayItem($event)"
  ></i>
  <i
    *ngIf="canAddCustomItem"
    class="array-editor__footer-button abb-icon abb-icon--large Information_circle_1"
    title="{{ translationService.propertySheet.AddSum | translate }}"
    (click)="addCustomArrayItem($event)"
  ></i>
  <i
    *ngIf="enableGroups && isGroupable"
    class="array-editor__footer-button abb-icon abb-icon--large Folder_new"
    title="{{ translationService.propertySheet.CreateGroupTooltip | translate }}"
    (click)="createNewGroup($event)"
  ></i>
</div>
