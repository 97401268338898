<c-table
  *ngIf="shouldRenderTable(); else noData"
  class="simple-component"
  [tableRowsWithPaginator]="tableRows"
  [tableColumns]="tableColumns"
  [tableColumnIds]="tableColumnIds"
  [pageSizeOptions]="viewConfig.tablePageSizeOptions"
  [pageSize]="viewConfig.tablePageSize"
  [headerBackgroundColor]="
    viewConfig.css.backgroundColor !== '' ? viewConfig.css.backgroundColor : defaultHeaderColor
  "
  [viewMode]="viewMode"
  [isFilterEnabled]="viewConfig.enableFilter"
  [alternateRowColors]="viewConfig.alternateTableRowColors"
  [tableComponentId]="tableComponentId"
  [numberOfRequestedDataPoints]="maxNumberOfRequestedDataPoints"
  [showFooter]="viewConfig.showFooter"
  [footerRowDict]="footerRowDict"
  [headerRowDict]="headerRowDict"
  (pageSizeChange)="pageSizeChange.emit($event)"
  (loadMoreData)="loadMoreData.emit()"
  (sortDirectionChange)="sortDirectionChange()"
  (storePreviewAndInlineChanges)="storePreviewAndInlineChanges($event)"
></c-table>
<ng-template #noData>
  <div
    *ngIf="dataStatus !== DataStatus.WaitingForData"
    [style.color]="noDataColor"
    table-inline-edit
  >
    {{ dataStatus }}
  </div>
</ng-template>
