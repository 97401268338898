import { ChangeDetectorRef, Component, ElementRef } from "@angular/core";
import { AxisPlotLinesOptions, SeriesOptionsType, YAxisOptions } from "highcharts";
import { RDS_LOCALIZATION_DICTIONARY } from "projects/rds/src/assets/i18n/models/rds-localization-dictionary";
import { DataConnectorDescriptor } from "projects/ui-core/src/lib/elements/models/store/data-connector-descriptor";
import { DataConnectorViewSelector } from "projects/ui-core/src/lib/elements/services/entity-selectors/data-connector-view.selector";
import { PRIMARY_X_AXIS_ID } from "projects/ui-core/src/lib/elements/services/highcharts/base-highcharts-options.helper";
import {
  AggregationFunction,
  BaseComponent,
  ChartComponent,
  ComponentConstructorParams,
  EditableWidget,
  formatValueByStringFormat,
  isDefined,
  LayoutBuilder,
  MaxConnectors,
  tryConvertToNumber,
  View
} from "ui-core";
import { DisplayStrategies } from "../../models/display-strategies/array-display-strategies";
import { RdsComponentCategory } from "../rds-component-category";
import { ArrayChartViewConfig } from "./view.config";

@MaxConnectors(20)
@Component({
  selector: "rds-array-chart",
  templateUrl: "./array-chart.component.html",
  styleUrls: ["./array-chart.component.scss"],
  providers: [
    {
      provide: BaseComponent,
      useExisting: ArrayChartComponent
    }
  ]
})
@LayoutBuilder(
  RdsComponentCategory.RDS,
  "ArrayChartComponent",
  "Plugin",
  "abb-icon",
  undefined,
  RDS_LOCALIZATION_DICTIONARY.layoutEditor.ArrayChart
)
@EditableWidget({
  fullName: "ArrayChartComponent",
  title: "array-chart",
  initialConfig: {
    dataConnectorQuery: {
      aggregationConfig: { timeAggregationFunction: AggregationFunction.Latest }
    }
  }
})
export class ArrayChartComponent extends ChartComponent {
  viewConfig!: ArrayChartViewConfig;
  connectorDescriptors: DataConnectorDescriptor[] = [];

  constructor(
    params: ComponentConstructorParams,
    hostElementRef: ElementRef,
    cdr: ChangeDetectorRef,
    public connectorViewSelector: DataConnectorViewSelector
  ) {
    super(params, hostElementRef, cdr);
  }

  @View(ArrayChartViewConfig)
  public get view() {
    return this.currentState.view as ArrayChartViewConfig;
  }

  protected setChartOptions(): void {
    const options: Highcharts.Options = {
      legend: {
        enabled: this.viewConfig.showLegend
      },
      title: {
        text: this.viewConfig.title ?? ""
      },
      lang: {
        noData: "No Data Available"
      },
      credits: { enabled: false },
      plotOptions: {
        line: {
          step: "left",
          marker: {
            enabled: false
          }
        },
        area: {
          fillOpacity: 0.5
        }
      },
      chart: {
        inverted: this.viewConfig.chartDisplayStrategy === DisplayStrategies.Bar
      },
      xAxis: [
        {
          id: PRIMARY_X_AXIS_ID,
          title: {
            text: this.viewConfig.xAxisTitle
          }
        }
      ]
    };

    this.mergeChartOptions(options);
  }

  protected updateChartData(): void {
    this.resolveDescriptors();
    this.resetChartData();

    if (this.connectorDescriptors.length === 0 || !isDefined(this.chartObject)) {
      return;
    }

    const series: SeriesOptionsType[] = [];
    const averageLines: AxisPlotLinesOptions[] = [];

    this.connectorDescriptors.forEach((connectorDescriptor) => {
      const connector = connectorDescriptor.connector;

      if (!isDefined(connector) || !isDefined(connector.dataPoints)) {
        return;
      }

      const data = connector.dataPoints[0]?.y ?? [];

      const options = {
        name: connector.title,
        type: this.viewConfig.chartDisplayStrategy.toLowerCase(),
        color: connectorDescriptor.connectorView?.color,
        data
      } as Highcharts.SeriesOptionsType;

      series.push(options);

      if (this.viewConfig.showAverage && data.length > 0) {
        const averageValue =
          data.reduce((acc: number, curr: number) => acc + curr, 0) / data.length;

        const averageLineOptions = {
          color: connectorDescriptor.connectorView?.color,
          dashStyle: "Dash",
          value: averageValue,
          width: 1,
          label: {
            text: "AVG"
          }
        } as AxisPlotLinesOptions;

        averageLines.push(averageLineOptions);
      }
    });

    const yAxes = this.createYAxes();

    if (yAxes.length > 0) {
      yAxes[0].plotLines = averageLines;
    }

    this.chartObject.update(
      {
        yAxis: yAxes,
        series
      },
      true,
      true,
      false
    );
  }

  private createYAxes(): YAxisOptions[] {
    const component = this;

    const defaultYAxisText =
      this.connectorDescriptors.length > 0
        ? this.connectorDescriptors[0].connector?.properties.unit
        : "";

    return this.viewConfig.yAxes.map(
      (yAxis, index) =>
        ({
          visible: !yAxis.isHidden,
          title: {
            text:
              isDefined(yAxis.axisTitle) && yAxis.axisTitle !== ""
                ? yAxis.axisTitle
                : defaultYAxisText
          },
          min: tryConvertToNumber(yAxis.min),
          max: tryConvertToNumber(yAxis.max),
          color: yAxis.color,
          lineColor: yAxis.color,
          gridLineColor: yAxis.color,
          opposite: index % 2 == 1,
          labels: {
            formatter: function () {
              return formatValueByStringFormat(
                Number(this.value),
                component.viewConfig.displayFormat
              );
            }
          }
        } as YAxisOptions)
    );
  }

  private resetChartData(): void {
    if (isDefined(this.chartObject)) {
      this.chartObject.update(
        {
          series: [],
          yAxis: []
        },
        false,
        true,
        false
      );
    }
  }

  private resolveDescriptors(): void {
    const evaluatedDescriptor = this.dynamicDefaultsEvaluator.collectAndEvaluate(
      this.view,
      this.dataAccessor
    );
    const interpolatedProperties =
      this.propertyInterpolationService.collectInterpolatedProperties<ArrayChartViewConfig>(
        this.currentState,
        evaluatedDescriptor.connectorDescriptors
      );
    this.viewConfig = interpolatedProperties.viewConfig;
    this.connectorDescriptors = interpolatedProperties.connectorDescriptors;
  }
}

