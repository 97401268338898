import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  ViewChild
} from "@angular/core";
import { LocalizationService } from "../../i18n/localization.service";
import { OfType, UserHelpFactory } from "../../meta/decorators";
import { Access } from "../../meta/models/access";
import { EditorType } from "../../meta/models/editor-type";
import { defaultValidationFunction } from "../../meta/models/property-descriptor";
import { PropertyInfo } from "../../meta/models/property-info";
import { TypeDescriptor } from "../../meta/models/type-descriptor";
import { ValidationFunction } from "../../meta/models/validation-function";
import { TypeProvider } from "../../meta/services/type-provider";
import { isEmptyOrNotDefined2 } from "../../ts-utils/helpers/is-empty.helper";
import { isDefined, isUndefined } from "../../ts-utils/helpers/predicates.helper";
import { CriticalError } from "../../ts-utils/models/critical-error";

@Component({
  selector: "base-editor",
  template: ""
})
@OfType(EditorType.Hidden)
export class BaseEditorComponent implements OnInit {
  private _value: any;
  private _isValid: boolean = true;
  @ViewChild("editorInput")
  editorInput?: ElementRef<HTMLInputElement>;

  constructor(
    protected cdr: ChangeDetectorRef,
    protected typeProvider: TypeProvider,
    protected translationService: LocalizationService
  ) {
    this.propertyValueChanged = new EventEmitter<any>();
    this.validateInput = new EventEmitter<any>();
  }

  public get value(): any {
    return this._value;
  }

  @Input()
  public set value(value) {
    this._value = value;
    this.cdr.detectChanges();
  }

  @Input() public propertyInfo: PropertyInfo<unknown>;
  @Input() itemOwner: any;
  @Input() public parentInfo: any;
  public typeDescriptor: TypeDescriptor;
  private allowEmpty = true;
  public isReadOnly = false;
  public tooltip = "";
  public validationFunction: ValidationFunction = defaultValidationFunction;
  public userHelp: string | UserHelpFactory = null;

  @Output() public propertyValueChanged: EventEmitter<any>;
  @Output() public validateInput: EventEmitter<any>;

  @HostBinding(`class.editor__wrapper--inline`) inlineAlignment: boolean = false;

  public get isValid(): boolean {
    return this._isValid;
  }

  ngOnInit(): void {
    const { value, descriptor } = this.propertyInfo;
    if (isDefined(descriptor)) {
      const {
        defaultValue,
        access,
        validationFunction,
        tooltipKey,
        allowEmpty,
        inlineAlignment,
        userHelp
      } = descriptor;

      this.value = isUndefined(value) ? defaultValue : value;
      this.allowEmpty = allowEmpty;
      this.isReadOnly = access === Access.ReadOnly;
      this.typeDescriptor = this.typeProvider.getDerivedType(value, descriptor);
      this.validationFunction = validationFunction;
      this.tooltip = tooltipKey !== "" ? this.translationService.get(tooltipKey) : "";
      this.inlineAlignment = inlineAlignment;
      this.userHelp = userHelp;

      this.cdr.detectChanges();
    }
  }

  public onValueChanged(newValue: any): void {
    if (!this.typeDescriptor) {
      throw new CriticalError("Undefined type descriptor");
    }
    this._isValid = this.validateValue(newValue);
    if (this._isValid) {
      this.propertyValueChanged.emit(newValue);
    }
    this.cdr.markForCheck();
  }

  refreshValue(value): void {
    this.value = value;
  }

  private validateValue(value: any): boolean {
    const isEmpty = isEmptyOrNotDefined2(value);
    const isValid = this.validationFunction(value, {
      itemOwner: this.itemOwner,
      parentInfo: this.parentInfo
    });
    const validationResult = !this.allowEmpty && isEmpty ? false : isValid;
    this.validateInput.emit(validationResult);
    return validationResult;
  }

  focus(): void {
    this.editorInput?.nativeElement.focus();
  }

  generatePlaceholderText(): string {
    if (isDefined(this.propertyInfo.placeholder)) {
      return this.propertyInfo.placeholder.combineWithRuntimeValue
        ? this.concatRuntimeValueAndPlaceholder()
        : this.propertyInfo.placeholder.text;
    }
    if (isDefined(this.propertyInfo.runtimeValue)) {
      return this.propertyInfo.runtimeValue as string;
    }

    return "";
  }

  private concatRuntimeValueAndPlaceholder(): string {
    return (
      (this.propertyInfo.runtimeValue ?? "") +
      " (" +
      this.translationService.get(this.propertyInfo.placeholder.text) +
      ")"
    );
  }
}
