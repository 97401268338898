import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { isUndefined } from "lodash";
import { iif, Observable, of } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { EnvironmentSelector, ViewMode, WebServicesConfiguration } from "ui-core";
import { CoreComponentCategoryProvider } from "../../../../../ui-core/src/lib/core/services/core-component-category-provider";
import {
  SidemenuComponents,
  ToolbarItem
} from "../../../../../ui-core/src/lib/shared/models/tool-bar-item";
import { ToolbarItemsGenerator } from "../../../../../ui-core/src/lib/shared/services/toolbar-items-generator.service";
import { FLEET_VIEW_REPORT_ID } from "../../models/constants/predefined-reports";

@Injectable()
export class RdsToolbarItemsGenerator extends ToolbarItemsGenerator {
  constructor(
    protected componentCategoryInfoProvider: CoreComponentCategoryProvider,
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private apiConfig: WebServicesConfiguration,
    protected environmentSelector: EnvironmentSelector
  ) {
    super(componentCategoryInfoProvider, environmentSelector);
  }

  private readonly previewModeCommonToolbarItems: ToolbarItem[] = [
    new ToolbarItem("abb-icon Map", SidemenuComponents.MapExplorer, "", () =>
      this.router.navigateByUrl(`/report/${FLEET_VIEW_REPORT_ID}`)
    ),
    new ToolbarItem("abb-icon Tree_view", SidemenuComponents.TreeExplorer, "")
  ];

  private readonly editModeCommonToolbarItems: ToolbarItem[] = [
    new ToolbarItem("abb-icon Database", SidemenuComponents.DataExplorer, "")
  ];

  private getCommonExplorerToolbarItems(viewMode: ViewMode): ToolbarItem[] {
    switch (viewMode) {
      case ViewMode.PreviewMode:
        return this.previewModeCommonToolbarItems;
      case ViewMode.EditMode:
        return this.editModeCommonToolbarItems;
      default:
        throw new Error(`View mode ${viewMode} not supported!`);
    }
  }

  private getExclusiveToolbarItems$(
    items: ToolbarItem[],
    viewMode: ViewMode
  ): Observable<ToolbarItem[]> {
    const customer = this.route.snapshot.queryParamMap.get("rootPath")?.split("/")[0];

    const isCustomerSelected = !isUndefined(customer);
    const shouldShowCopilotIcon = (): boolean =>
      isCustomerSelected && viewMode === ViewMode.PreviewMode;

    const $items = of(items);
    const $copilot = this.http
      .get<boolean>(`${this.apiConfig.itemsServiceUrl}/access-management/copilot/${customer}`)
      .pipe(
        map((hasAccess: boolean) =>
          hasAccess
            ? items.concat(
              new ToolbarItem(
                "abb-icon Copilot",
                SidemenuComponents.Copilot,
                "",
                () => window.open(`${window.location.origin}/copilot/customer/${customer}`, "_blank")
              )
            )
            : items
        )
      );

    return iif(shouldShowCopilotIcon, $copilot, $items);
  }

  generateExplorerToolbarItems$(): Observable<ToolbarItem[]> {
    return this.environmentSelector.selectViewMode().pipe(
      map((viewMode) => {
        return {
          commonItems: this.getCommonExplorerToolbarItems(viewMode),
          viewMode
        };
      }),
      switchMap(({ commonItems, viewMode }) =>
        this.getExclusiveToolbarItems$(commonItems, viewMode)
      )
    );
  }
}

