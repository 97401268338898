import { Options, SeriesOptionsType } from "highcharts";
import { formatValueByStringFormat } from "../../../core/services/value-formatter.service";
import { DateFormatterService } from "../../../environment/services/date-formatter.service";
import { getEntityTitle } from "../../../meta/helpers/get-title.helper";
import { tryConvertToNumber } from "../../../ts-utils/helpers";
import { ICategoryBarDisplayConfig } from "../../models/i-view-config/i-base-display-config";
import { DataConnectorDescriptor } from "../../models/store/data-connector-descriptor";
import { PivotTableHelper } from "../pivot-table-helper";
import { getChartTooltip } from "../tooltip.helper";
import {
  PRIMARY_X_AXIS_ID,
  PRIMARY_Y_AXIS_ID,
  Y_AXIS_PREFIX,
  getSeriesAxisOptions
} from "./base-highcharts-options.helper";

export class CategoryBarDisplayService {
  constructor(protected dateFormatter: DateFormatterService, private chartType: string) {}

  getChartOptions(
    viewConfig: ICategoryBarDisplayConfig,
    dataConnectorDescriptors: DataConnectorDescriptor[]
  ): Options {
    const pivotTable = new PivotTableHelper(
      dataConnectorDescriptors.map((x) => x.connector),
      this.dateFormatter
    );
    const allAxisCategories = pivotTable.categories;
    const series: SeriesOptionsType[] = dataConnectorDescriptors.map((connDescriptor, index) => {
      return {
        color: connDescriptor.connectorView.color,
        name: getEntityTitle(connDescriptor.connector),
        type: undefined,
        data: pivotTable.getVector(connDescriptor.connector),
        ...getSeriesAxisOptions(connDescriptor, viewConfig)
      };
    });
    const chartOptions = this.buildOptions(
      allAxisCategories,
      viewConfig,
      this.chartType,
      dataConnectorDescriptors
    );
    chartOptions.series = series;
    return chartOptions;
  }

  private buildOptions(
    categories0: string[],
    viewConfig: ICategoryBarDisplayConfig,
    chartType: string,
    dataConnectorDescriptors: DataConnectorDescriptor[]
  ): Options {
    const component = this;
    const opt: Options = {
      chart: {
        type: chartType
      },
      xAxis: [
        {
          id: PRIMARY_X_AXIS_ID,
          categories: categories0,
          type: "category",
          title: {
            text: viewConfig.xAxisTitle
          }
        }
      ],
      yAxis: getYAxes(viewConfig),
      legend: {
        enabled: viewConfig.showLegend,
        reversed: viewConfig.stacked
      },
      tooltip: {
        backgroundColor: "rgb(255, 255, 255, 1)"
      },

      plotOptions: {
        series: {
          stacking: viewConfig.stacked ? "normal" : (false as any),
          animation: false,
          tooltip: {
            headerFormat: "",
            pointFormatter: function () {
              return getChartTooltip(
                dataConnectorDescriptors,
                viewConfig.displayFormat,
                this,
                component.dateFormatter,
                false
              );
            }
          }
        },
        bar: {
          groupPadding: 0,
          pointPadding: 0.1
        },
        column: {
          groupPadding: 0,
          pointPadding: 0.1,
          dataLabels: {
            enabled: viewConfig.showColumnDataLabels,
            crop: viewConfig.stacked,
            overflow: viewConfig.stacked ? "justify" : "allow",
            formatter: function () {
              return component.formatNumber(this.y, viewConfig);
            },
            y: -5
          }
        }
      }
    };

    return opt;
  }

  private formatNumber(value: any, viewConfig: ICategoryBarDisplayConfig): string {
    return formatValueByStringFormat(value, viewConfig.displayFormat) ?? "";
  }
}

function getYAxes(displayConfig: ICategoryBarDisplayConfig): Highcharts.YAxisOptions[] {
  return displayConfig.yAxes.map((axisConfig, index) => ({
    id: index === 0 ? PRIMARY_Y_AXIS_ID : Y_AXIS_PREFIX + index.toString(),
    title: {
      text: axisConfig.axisTitle,
      style: { color: axisConfig.color }
    },
    labels: {
      style: { color: axisConfig.color }
    },
    min: tryConvertToNumber(axisConfig.min),
    max: tryConvertToNumber(axisConfig.max),
    opposite: index % 2 === 1,
    visible: !axisConfig.isHidden
  }));
}
