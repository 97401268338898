import { DecoratorDelegateContext } from "../../core/models/decorator-delegate-context";
import {
  COMPONENT_STATE_VIEW_MODEL,
  DATA_CONNECTOR_VIEW_MODEL
} from "../../elements/models/entity-type.constants";
import {
  API_DATASOURCE,
  GROUPED_AND_TABULAR_DATASOURCE,
  GROUPED_AND_TABULAR_DATASOURCE__TABULAR_DATA_SOURCE,
  HELP_LANDING_PAGE,
  VALUE_DATASOURCE
} from "../../elements/models/help-constants";
import { PropertyInfo, SelectionOption, TypeDescriptor, TypeProvider } from "../../meta";
import { CriticalError } from "../../ts-utils/models/critical-error";
import { DataSourceDto } from "../models";
import { DataSourceDecoratorContext } from "../models/data-source/data-source-decorator-context";
import {
  API_DATA_SOURCE,
  DataSourceType,
  EQUIPMENT_DATA_SOURCE,
  GROUPED_DATA_SOURCE,
  SIGNAL_DATA_SOURCE,
  TABULAR_DATA_SOURCE,
  VALUE_DATA_SOURCE
} from "../models/data-source/data-source.type";

export function dataSourceIsApplicable(
  dataSourceType: string,
  parentIsDataConnector: boolean
): boolean {
  switch (dataSourceType) {
    case GROUPED_DATA_SOURCE:
    case TABULAR_DATA_SOURCE:
    case API_DATA_SOURCE:
      return !parentIsDataConnector;
    case SIGNAL_DATA_SOURCE:
    case VALUE_DATA_SOURCE:
      return parentIsDataConnector;
    case EQUIPMENT_DATA_SOURCE:
    default:
      return true;
  }
}

export function onDataSourceTypeChange(
  context: DataSourceDecoratorContext,
  owner: DataSourceDto,
  propertyChange: PropertyInfo<DataSourceType>
): DataSourceDto {
  const deserializer = context.dataSourceDeserializer;
  if (!deserializer) {
    throw new CriticalError("Undefined data source deserializer");
  }
  const targetDataSource = owner;
  if (!targetDataSource) {
    throw new CriticalError("Undefined data source");
  }
  const newDataSource = deserializer.convert({
    ...targetDataSource,
    typeName: propertyChange.value
  });
  return newDataSource;
}

export function getDataSourcesEnum(context: DecoratorDelegateContext): SelectionOption[] {
  return getDataSourceTypes(context.services.typeProvider)
    .filter((dataSourceType: TypeDescriptor) => !dataSourceType.isVirtual)
    .filter((dataSourceType: TypeDescriptor) => {
      const typeName: string = (new dataSourceType.constructorFunction() as DataSourceDto).typeName;
      const parentIsDataConnector: boolean =
        context.services.genericDataSourceService.isDataConnector(context.ownerInstance);
      return dataSourceIsApplicable(typeName, parentIsDataConnector);
    })
    .map((type: TypeDescriptor) => {
      const dataSourceTypeName = getDataSourceTypeTitle(type);
      const title = context.services.localizationService.propertySheet[dataSourceTypeName];
      return {
        key: type.name,
        title
      };
    });
}

function getDataSourceTypeTitle(dataSourceType: TypeDescriptor): string {
  if (!dataSourceType) {
    throw new CriticalError("Undefined data source type");
  }
  return dataSourceType.name.replace("DataSourceDto", "");
}

export function getDataSourceTypeName(dataSourceType: TypeDescriptor): string {
  if (!dataSourceType) {
    throw new CriticalError("Undefined data source type");
  }
  return dataSourceType.name.replace("DataSourceDto", "").toLowerCase();
}

export function getDataSourceTypes(typeProvider: TypeProvider): TypeDescriptor[] {
  if (!typeProvider) {
    throw new CriticalError("Undefined type provider");
  }

  const dataSourceType = typeProvider.getType("DataSourceDto");
  return typeProvider.getAllInheritedFrom(dataSourceType);
}

export function getDataSourceHelpLink(context: DecoratorDelegateContext): string {
  if (context.ownerInstance.typeName === DATA_CONNECTOR_VIEW_MODEL) {
    if (context.ownerInstance.dataSource.typeName === VALUE_DATA_SOURCE) {
      return VALUE_DATASOURCE;
    }
  } else if (context.ownerInstance.typeName === COMPONENT_STATE_VIEW_MODEL) {
    switch (context.ownerInstance.dataConnectorQuery.typeName) {
      case GROUPED_DATA_SOURCE:
        return GROUPED_AND_TABULAR_DATASOURCE;
      case TABULAR_DATA_SOURCE:
        return GROUPED_AND_TABULAR_DATASOURCE__TABULAR_DATA_SOURCE;
      case API_DATA_SOURCE:
        return API_DATASOURCE;
      default:
        return HELP_LANDING_PAGE;
    }
  }
  return HELP_LANDING_PAGE;
}
