import { isDefined, isNotDefined } from "../../ts-utils/helpers/predicates.helper";
import { capitalizeText } from "../../ts-utils/helpers/string.helper";
import { Maybe } from "../../ts-utils/models/maybe.type";
import { BorderRadiusDto } from "../models/border-radius";
import { BorderSide, BorderStyle, BorderStyleDto } from "../models/border-style";
import { cssSizeParser } from "../models/component-size.constants";
import { ComponentStyleDto } from "../models/component-style";

export function resolveElementBorderStyle(
  css: Partial<ComponentStyleDto>
): Partial<CSSStyleDeclaration> {
  let borderCssStyle: Partial<CSSStyleDeclaration> = {
    borderLeft: "",
    borderRight: "",
    borderTop: "",
    borderBottom: "",
    borderRadius: ""
  };
  let borderRadius: string = "";

  if (isDefined(css.border)) {
    const { radius, sides, borderWidth, borderColor } = css.border as BorderStyleDto;
    if (isDefined(radius) && (isDefined(radius.radiusInput) || isDefined(radius.radiusSlider))) {
      borderRadius = `${radius.radiusSlider}px`;
      borderCssStyle = { ...borderCssStyle, borderRadius };
    }

    sides.forEach((side: string) => {
      borderCssStyle = {
        ...borderCssStyle,
        [BorderSide[side]]: `${borderWidth}px ${css.border?.borderStyle} ${borderColor}`
      };
    });
  }
  return borderCssStyle;
}

export function resolveBorderStyleFromElement(element: HTMLElement): BorderStyleDto {
  const sides: string[] = Object.values(BorderSide)
    .filter((prop) => element.style[prop]?.length > 0)
    .map((setProp) => getBorderSideKey(setProp));

  return new BorderStyleDto({
    radius: new BorderRadiusDto(cssSizeParser(element.style.borderRadius).value),
    sides,
    borderColor: resolveBorderColor(sides[0], element),
    borderWidth: resolveBorderWidth(sides[0], element),
    borderStyle: resolveBorderStyle(sides[0], element)
  });
}

function getBorderSideKey(borderSideValue: BorderSide): string {
  return Object.keys(BorderSide)[Object.values(BorderSide).indexOf(borderSideValue)];
}

function resolveBorderColor(firstSide: Maybe<string>, element: HTMLElement): string {
  if (isNotDefined(firstSide)) {
    return "";
  }
  return element.style[BorderSide[firstSide] + "Color"];
}

function resolveBorderStyle(firstSide: Maybe<string>, element: HTMLElement): string {
  if (isNotDefined(firstSide)) {
    return BorderStyle.Solid;
  }
  return capitalizeText(element.style[BorderSide[firstSide] + "Style"]);
}

function resolveBorderWidth(firstSide: Maybe<string>, element: HTMLElement): number {
  if (isNotDefined(firstSide)) {
    return 0;
  }
  return cssSizeParser(element.style[BorderSide[firstSide] + "Width"]).value;
}
