import { Options, YAxisLabelsOptions, YAxisPlotBandsOptions } from "highcharts";
import { formatValueByStringFormat } from "../../../core/services/value-formatter.service";
import {
  Maybe,
  first,
  isEmptyOrNotDefined,
  roundToTwoDecimals,
  tryConvertToNumber
} from "../../../ts-utils";
import { getTextColorForNoDataStatus } from "../../helpers/color.helper";
import { getSingleValueFromConnector } from "../../helpers/component-data-accessor.helper";
import { DataStatus } from "../../models";
import { DEFAULT_CHART_TEXT_COLOR_HEX } from "../../models/colors.constants";
import { IBaseDisplayConfig } from "../../models/i-view-config/i-base-display-config";
import { IGaugeDisplayConfig } from "../../models/i-view-config/i-gauge-display-config";
import { DataConnectorDescriptor } from "../../models/store/data-connector-descriptor";
import { LimitMarkerHelper } from "../limit.helper";
import { AXIS_LABEL_FONT_SIZE, PRIMARY_Y_AXIS_ID } from "./base-highcharts-options.helper";
import { GaugeDisplayService } from "./gauge-display.service";
import { switchedOnOff } from "./requires-full-redraw.helper";

export const solidGaugeInnerRadiusInPercent = 80;
const solidGaugeOuterRadiusInPercent = 100;

export class SolidGaugeDisplayService extends GaugeDisplayService {
  constructor() {
    super();
    this.chartType = "solidgauge";
    this.isTargetAsAxis = true;
  }

  protected requiresFullRedraw(
    previousProcessedViewConfig: IBaseDisplayConfig,
    currentProcessedViewConfig: IBaseDisplayConfig,
    previousChartOptions: Maybe<Options>,
    currentChartOptions: Maybe<Options>
  ): boolean {
    const isValueColorChanged: boolean =
      (previousChartOptions?.plotOptions?.series?.dataLabels as any)?.style?.color !==
      (currentChartOptions?.plotOptions?.series?.dataLabels as any)?.style?.color;
    const prev = previousProcessedViewConfig as IGaugeDisplayConfig;
    const cur = currentProcessedViewConfig as IGaugeDisplayConfig;
    return isValueColorChanged || switchedOnOff(prev.limits.target, cur.limits.target);
  }

  protected getSpecificGaugeOptions(
    viewConfig: IGaugeDisplayConfig,
    dataConnectorDescriptors: Maybe<DataConnectorDescriptor>[],
    dataStatus: DataStatus
  ): Options {
    const value = getSingleValueFromConnector(first(dataConnectorDescriptors)?.connector);
    const markerHelper = new LimitMarkerHelper(viewConfig.limits);
    const markers = markerHelper.getMarkers(6, 6);
    const min = Number(roundToTwoDecimals(tryConvertToNumber(viewConfig.min)));
    const max = Number(roundToTwoDecimals(tryConvertToNumber(viewConfig.max)));
    const options: Options = {
      chart: {
        animation: false
      },
      noData: {
        style: {
          color: getTextColorForNoDataStatus(
            dataStatus,
            viewConfig.foregroundColor ?? DEFAULT_CHART_TEXT_COLOR_HEX
          )
        }
      },
      plotOptions: {
        solidgauge: {
          dataLabels: {
            borderWidth: 0,
            y: 0,
            style: {
              textOutline: "none"
            }
          }
        }
      },
      pane: {
        size: "140%",
        center: ["50%", "85%"],
        startAngle: -90,
        endAngle: 90,
        background: [
          {
            innerRadius: `${solidGaugeInnerRadiusInPercent}%`,
            outerRadius: `${solidGaugeOuterRadiusInPercent}%`,
            shape: "arc"
          }
        ]
      },
      yAxis: [
        {
          id: PRIMARY_Y_AXIS_ID,
          stops: [[0, viewConfig.primaryColor]],
          tickPositions: [min, max],
          minorTickInterval: null,
          lineWidth: 0,
          tickLength: 0,
          minorTickLength: 0,
          labels: this.getYAxisLabelOptions(viewConfig),
          plotBands: this.getYAxisPlotBands(viewConfig)
        },
        {
          linkedTo: 0,
          lineWidth: 0,
          tickLength: 0,
          tickWidth: 0,
          minorTickLength: 0,
          tickPositions: markers.map((marker) => marker.value),
          labels: this.getYAxisLimitsLabelsOptions(
            viewConfig.displayFormat,
            viewConfig.foregroundColor
          )
        }
      ],
      series: [
        {
          id: "value_solidgauge",
          type: "solidgauge",
          innerRadius: `${solidGaugeInnerRadiusInPercent}%`,
          /** The series color can be seen for 1px behind  plotBands (limits), thus reduced radius */
          radius: `${solidGaugeOuterRadiusInPercent - 1}%`,
          name: viewConfig.title ?? "",
          data: value != null ? [value] : []
        }
      ]
    };

    if (
      !isEmptyOrNotDefined(viewConfig.neutralColor) &&
      options.pane != null &&
      options.pane.background != null
    ) {
      options.pane.background[0].backgroundColor = {
        stops: [
          [0, viewConfig.neutralColor],
          [1, viewConfig.neutralColor]
        ]
      };
    }
    return options;
  }

  protected getYAxisLabelOptions(viewConfig: IGaugeDisplayConfig): YAxisLabelsOptions {
    const yAxisLabelYPosition = AXIS_LABEL_FONT_SIZE;
    const yAxisLabelPosition = -AXIS_LABEL_FONT_SIZE;
    return {
      enabled: viewConfig.showYAxisLabels,
      distance: yAxisLabelPosition,
      y: yAxisLabelYPosition,
      style: {
        fontSize: AXIS_LABEL_FONT_SIZE + "px"
      }
    };
  }

  private getYAxisLimitsLabelsOptions(
    displayFormat: string,
    foregroundColor: Maybe<string>
  ): YAxisLabelsOptions {
    const labelDistance = AXIS_LABEL_FONT_SIZE;
    return {
      distance: labelDistance,
      useHTML: true,
      /** Cannot be and arrow fn, we need to preserve 'this' binding */
      formatter: function () {
        return formatValueByStringFormat(Number(this.value), displayFormat);
      },
      style: {
        fontSize: AXIS_LABEL_FONT_SIZE + "px",
        fontFamily: "ABB Font",
        fontWeight: "normal",
        border: "0px",
        color: foregroundColor
      }
    };
  }

  getYAxisPlotBands(viewConfig: IGaugeDisplayConfig): YAxisPlotBandsOptions[] {
    const plotBands = super.getYAxisPlotBands(viewConfig);
    const zIndexAboveSeries = 5;
    return plotBands.map((plotBand: YAxisPlotBandsOptions): YAxisPlotBandsOptions => {
      const plotLineInnerRadius = solidGaugeOuterRadiusInPercent - 5;
      return {
        ...plotBand,
        zIndex: zIndexAboveSeries,
        outerRadius: `${solidGaugeOuterRadiusInPercent}%`,
        innerRadius: `${plotLineInnerRadius}%`
      };
    });
  }
}
