import { construct } from "../../../core/services/construct.helper";
import { FILTERING } from "../../../elements/models/help-constants";
import { LOCALIZATION_DICTIONARY } from "../../../i18n/models/localization-dictionary";
import {
  Configurable,
  ConfigurableEnum,
  ConfigurationCategory,
  EditableType,
  Serializable
} from "../../../meta/decorators";
import { EditorType, PropertyCategory } from "../../../meta/models";
import { ValidationContext } from "../../../meta/models/validation-context";
import { isWhiteSpaceOrNotDefined } from "../../../ts-utils/helpers/string.helper";
import {
  getEntities,
  getSelectedEntityColumns
} from "../../helpers/generic-data-source-entities.helper";
import { FilterWhere } from "../filter-where";
import { OrderBy } from "../order-by";
import { DataSourceDto } from "./data-source";
import { GENERIC_DATA_SOURCE, GenericDataSourceType } from "./data-source.type";

export function validateWhereJson(
  expression: string,
  _validationContext: ValidationContext
): boolean {
  if (!isWhiteSpaceOrNotDefined(expression)) {
    try {
      JSON.parse(expression);
    } catch (SyntaxError) {
      return false;
    }
  }
  return true;
}

// @dynamic
@EditableType({ fullName: GENERIC_DATA_SOURCE, title: "generic-data-source-dto", virtual: true })
export abstract class GenericDataSourceDto extends DataSourceDto {
  typeName: GenericDataSourceType = GENERIC_DATA_SOURCE;

  @ConfigurationCategory(PropertyCategory.Data, LOCALIZATION_DICTIONARY.propertySheet.DataSource)
  @ConfigurableEnum({
    enumSource: getEntities,
    displayName: LOCALIZATION_DICTIONARY.propertySheet.TableView
  })
  @Serializable("")
  entity: string;

  @ConfigurationCategory(PropertyCategory.Data, LOCALIZATION_DICTIONARY.propertySheet.DataSource)
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Where,
    editorType: EditorType.TextBox,
    tooltipKey: LOCALIZATION_DICTIONARY.propertySheet.WhereTooltip,
    validationFunction: validateWhereJson,
    userHelp: FILTERING
  })
  @Serializable()
  whereJson: string;

  filter: FilterWhere;

  @ConfigurationCategory(PropertyCategory.Data, LOCALIZATION_DICTIONARY.propertySheet.DataSource)
  @ConfigurableEnum({ enumSource: getSelectedEntityColumns, displayName: "Simple Filter on" })
  @Serializable("")
  simpleFilterColumn!: string;

  @ConfigurationCategory(PropertyCategory.Data, LOCALIZATION_DICTIONARY.propertySheet.DataSource)
  @Configurable({
    displayName: "Simple Filter value",
    editorType: EditorType.TextBox
  })
  @Serializable()
  simpleFilterValue: string;

  // @ConfigurationCategory(PropertyCategory.Data, localizationDictionary.PropertySheet.DataSource)
  // @Configurable({ displayName: "Order By", editorType: EditorType.SeparateInstance })
  @Serializable(new OrderBy())
  orderBy!: OrderBy;

  @ConfigurationCategory(PropertyCategory.Data, LOCALIZATION_DICTIONARY.propertySheet.DataSource)
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.MaxRecords,
    editorType: EditorType.Number
  })
  @Serializable(1000)
  maxRecords?: number;

  constructor(entity?: string) {
    super();
    construct(this, { entity }, GENERIC_DATA_SOURCE);
  }
}

export function isEmptyGenericSource(dataSource: GenericDataSourceDto): boolean {
  return dataSource == null || dataSource.entity == null;
}
