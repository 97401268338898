<div
  class="app-body"

>
  <sidebar (toggleReportBrowser)="toggleReportBrowserSidebar()">
    <ng-content></ng-content>
  </sidebar>
  <div class="app-body__container">
    <runtime-filter-toolbar *ngIf="isFilterToolbarOpened"></runtime-filter-toolbar>
    <div [ngClass]="contentWrapperClass">
      <div
        class="app-body__content"
        [ngClass]="{ 'app-body__content--background-white': templateBuilderMode }"
      >
        <router-outlet (activate)="onRouterActivated($event)"></router-outlet>
      </div>
      <property-sheet *ngIf="isPropertySheetOpened"></property-sheet>
    </div>
  </div>
</div>
