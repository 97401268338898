import { fontSize16 } from "../../../_typography";
import { SingleValueViewConfig } from "../components/single-value/view-config";
import { SingleValueDisplayStrategy } from "../models/display-strategies/single-value-display-strategies";
import { FontSizeParams } from "../models/font-size-params";
import {
  GAUGE_SCALING_FACTOR,
  getGaugeDataLabelSize,
  MIN_DIAL_GAUGE_DIMENSIONS,
  SOLID_GAUGE_MIN_HEIGHT,
  SOLID_GAUGE_MIN_WIDTH
} from "../services/highcharts/gauge.helper";
import {
  getScaledFontSizeByFormat,
  roundToClosestFontSize
} from "../services/scalable-font-size.helper";

export function resolveFontSizeForSingleValueComponent(viewConfig: SingleValueViewConfig): number {
  if (
    viewConfig.displayStrategy === SingleValueDisplayStrategy.Value ||
    viewConfig.displayStrategy === SingleValueDisplayStrategy.TrendValue ||
    viewConfig.displayStrategy === SingleValueDisplayStrategy.CircleValue
  ) {
    return calculateForTextualComponent(viewConfig);
  }

  return calculateForGaugeComponent(viewConfig);
}

function calculateForTextualComponent(viewConfig: SingleValueViewConfig): number {
  const { widthInPx: width, heightInPx: height } = viewConfig.runtimeView.runtimeSize;

  const fontSizeParams: FontSizeParams = {
    widthInPx: width,
    heightInPx: height,
    scalingFactor:
      viewConfig.displayStrategy === SingleValueDisplayStrategy.CircleValue ? 0.3 : 0.4,
    minSize: fontSize16
  };

  const scaledFontSize: number = getScaledFontSizeByFormat(viewConfig.titleFormat, fontSizeParams);
  return roundToClosestFontSize(scaledFontSize, false);
}

function calculateForGaugeComponent(viewConfig: SingleValueViewConfig): number {
  const { widthInPx: width, heightInPx: height } = viewConfig.runtimeView.runtimeSize;

  let minComponentWidthPx: number = SOLID_GAUGE_MIN_WIDTH;
  let minComponentHeightPx: number = SOLID_GAUGE_MIN_HEIGHT;

  if (
    viewConfig.displayStrategy === SingleValueDisplayStrategy.MultiDialGauge ||
    viewConfig.displayStrategy === SingleValueDisplayStrategy.DialGauge
  ) {
    minComponentWidthPx = MIN_DIAL_GAUGE_DIMENSIONS;
    minComponentHeightPx = MIN_DIAL_GAUGE_DIMENSIONS;
  }

  const labelFontSize = getGaugeDataLabelSize({
    componentWidthPx: width,
    componentHeightPx: height,
    minComponentWidthPx,
    minComponentHeightPx,
    scalingFactor: GAUGE_SCALING_FACTOR,
    minFontSizePx: fontSize16
  });
  return roundToClosestFontSize(labelFontSize, false);
}
