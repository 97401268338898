import { Injectable, Renderer2, RendererFactory2 } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { v4 as uuid } from "uuid";
import { LayoutEditorLocalizationDictionary, SidebarLocalizationDictionary } from "../../i18n";
import { LocalizationService } from "../../i18n/localization.service";
import { LayoutBuilder } from "../../meta";
import { removeDuplicates } from "../../ts-utils/helpers/array.helper";
import { toDictionary } from "../../ts-utils/helpers/dictionary.helper";
import { capitalizeAfterSpaceRemoval } from "../../ts-utils/helpers/string.helper";
import { Dictionary } from "../../ts-utils/models/dictionary.type";
import { IframeViewConfig } from "../components/generic-iframe/view-config";
import { extractIframeConfig } from "../helpers/template-builder.helper";
import { GENERIC_IFRAME } from "../models/element-type.constants";
import { TemplateElement } from "../models/template-builder-models";
import { StrategyDefaultsOverrideService } from "./strategy-defaults-override.service";

@Injectable({ providedIn: "root" })
export class TemplateBuilderElementsService {
  categoriesChanged: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  private _templateElements: TemplateElement[] = [];
  private renderer: Renderer2;

  constructor(
    private strategyDefaultsOverrideService: StrategyDefaultsOverrideService,
    private localizer: LocalizationService,
    rendererFactory: RendererFactory2
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  get templateElements(): TemplateElement[] {
    return this._templateElements;
  }

  set templateElements(elements: TemplateElement[]) {
    this._templateElements = elements;
    this.attachCategories();
    this.attachCategoryComponents();
    this.strategyDefaultsOverrideService.setStrategyOverrides(this.getComponentsOverrides());
  }

  private attachCategories(): void {
    const categories: string[] = removeDuplicates(
      this.templateElements.map((templateElement) => templateElement.category)
    );
    this.updateTranslations(categories, true);
    this.categoriesChanged.next(categories);
  }

  private attachCategoryComponents(): void {
    const componentNames: string[] = this.templateElements.map((element) => element.component);
    this.updateTranslations(componentNames, false);

    this.templateElements.forEach((element) => {
      const componentName = capitalizeAfterSpaceRemoval(element.component);
      const className = `com_${uuid()}`;
      const style = this.renderer.createElement("style");
      style.type = "text/css";
      style.innerHTML = `
      .${className} {
        background-image: url('data:image/x-icon;base64,${element.icon}');
      }
    `;
      this.renderer.appendChild(document.head, style);
      LayoutBuilder(
        element.category,
        GENERIC_IFRAME,
        className,
        "dasboard-icon com-icon",
        this.localizer.layoutEditor[componentName]
      )();
    });
  }

  private updateTranslations(translationKeys: string[], isCategoryTranslation: boolean): void {
    const localizationDictionary: Dictionary<string> = {};
    const i18nDictionary: Dictionary<string> = {};
    translationKeys.forEach((key) => {
      const translationKey = isCategoryTranslation ? key : capitalizeAfterSpaceRemoval(key);
      const localizationPrefix = isCategoryTranslation ? "Sidebar." : "LayoutEditor.";
      localizationDictionary[translationKey] = `${localizationPrefix}${translationKey}`;
      i18nDictionary[translationKey] = key;
    });

    let translationUpdates: Dictionary<Dictionary<string>> = {};
    let targetLocalizer: SidebarLocalizationDictionary | LayoutEditorLocalizationDictionary;

    if (isCategoryTranslation) {
      translationUpdates = { Sidebar: i18nDictionary };
      targetLocalizer = this.localizer.sidebar;
    } else {
      translationUpdates = { LayoutEditor: i18nDictionary };
      targetLocalizer = this.localizer.layoutEditor;
    }

    Object.assign(targetLocalizer, localizationDictionary);
    this.localizer.setTranslation(translationUpdates);
  }

  getComponentsOverrides(): Dictionary<Partial<IframeViewConfig>> {
    return toDictionary(
      this.templateElements,
      (element) => this.localizer.layoutEditor[capitalizeAfterSpaceRemoval(element.component)],
      (element) => extractIframeConfig(element.html)
    );
  }
}
