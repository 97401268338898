import { isDevMode } from "@angular/core";
import { isEmpty } from "lodash";
import { Dispatcher } from "../../dispatcher";
import { TypeProvider } from "../../meta/services/type-provider";
import { isNotDefined } from "../../ts-utils/helpers/predicates.helper";
import { capitalizeText } from "../../ts-utils/helpers/string.helper";
import { DeepPartial } from "../../ts-utils/models/deep-partial.type";
import { IframeViewConfig, TextAlignment } from "../components/generic-iframe/view-config";
import { ComponentCssSize } from "../models/component-size";
import { cssSizeParser } from "../models/component-size.constants";
import { ComponentStateDto } from "../models/component-state";
import { CssPosition } from "../models/css-position";
import { GENERIC_IFRAME } from "../models/element-type.constants";
import { ComponentStateSelector } from "../services/entity-selectors/component-state.selector";
import { ComponentCounterActions, ComponentStateActions, ROOT_COMPONENT_ID } from "../store";
import { resolveBorderStyleFromElement, resolveElementBorderStyle } from "./border.helper";

export const KEEP_TOGETHER_CLASS = "keep-together";

export function convertReportToTemplate(componentStateSelector: ComponentStateSelector): string {
  const card = componentStateSelector.getChildrenAsArray(ROOT_COMPONENT_ID)[0];
  const iframeWidgets = componentStateSelector
    .getManyById(card.childrenIds)
    .filter((component) => component.type === GENERIC_IFRAME);

  const iframeElements = iframeWidgets.map((iframeWidget) => {
    const view = iframeWidget.view as IframeViewConfig;
    return createIframeElement(view);
  });

  return generateTemplateHtml(iframeElements, componentStateSelector);
}

export function refreshReportFromTemplate(
  templateText: string,
  componentSelector: ComponentStateSelector,
  dispatcher: Dispatcher
): void {
  const cardId = componentSelector.getRoot().childrenIds[0];
  dispatcher.dispatch(
    ComponentStateActions.deleteMany({
      targetComponents: componentSelector.getChildrenAsArray(cardId)
    })
  );

  const templateHtml = new DOMParser().parseFromString(templateText, "text/html");
  const iframes = templateHtml.querySelectorAll("iframe");
  const componentsCount = componentSelector.getComponentCount();
  iframes.forEach((iframe, index) => {
    iframe.src = createDashboardsSrc(iframe.src);
    const newComponent = createIframeComponent(iframe, componentsCount + index);
    dispatcher.dispatch(ComponentStateActions.addOne({ newComponent, parentId: cardId }));
  });
  const componentCounter = parseInt(templateHtml.body.getAttribute("bmi_id_max") ?? "0");
  dispatcher.dispatch(ComponentCounterActions.setCounter({ value: componentCounter }));
}

function createIframeElement(view: IframeViewConfig): HTMLIFrameElement {
  const convertedWidth = convertStyleProp(view.size.width);
  const convertedHeight = convertStyleProp(view.size.height);
  const convertedTop = convertStyleProp(view.css.top);
  const convertedLeft = convertStyleProp(view.css.left);

  const iframe = document.createElement("iframe");
  iframe.id = view.hostId;
  const urlParams = new URLSearchParams(view.src);
  urlParams.set("ElementID", iframe.id);
  iframe.src = decodeURIComponent(urlParams.toString());
  iframe.src = createTemplateSrc(iframe.src);
  iframe.frameBorder = "0";
  iframe.style.width = convertedWidth;
  iframe.style.height = convertedHeight;
  iframe.style.order = view.css.order;
  iframe.style.textAlign = view.textAlignment;
  iframe.style.overflow = view.overflow;
  iframe.style.padding = view.css.padding;
  iframe.style.margin = view.css.margin;
  iframe.style.clear = view.clear;
  iframe.style.float = view.dock;
  Object.assign(iframe.style, resolveElementBorderStyle(view.css));
  if (view.keepTogether) {
    iframe.classList.add(KEEP_TOGETHER_CLASS);
  }
  if (view.css.position === CssPosition.absolute) {
    iframe.style.left = convertedLeft;
    iframe.style.top = convertedTop;
    iframe.style.position = CssPosition.absolute;
  } else {
    iframe.style.position = CssPosition.relative;
  }
  return iframe;
}

function convertStyleProp(styleProp: string): string {
  const parsedProp = cssSizeParser(styleProp);
  // KBST doesn't suppot floating values
  return Math.floor(parsedProp.value) + parsedProp.unit;
}

function generateTemplateHtml(
  iframeElements: HTMLIFrameElement[],
  componentStateSelector: ComponentStateSelector
) {
  return `<!DOCTYPE html>
          <!-- #include file="../scripts/TemplateMenus.inc" -->
          <html>
            <head>
              <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
              <link href="../css/CIMSstyles.css" rel="stylesheet" type="text/css" media="screen" />
              <link href="../css/CIMSPrintStyles.css" rel="stylesheet" type="text/css" media="print" />
              <!-- #include file="../Scripts/KBSHeaderInclude.inc" -->
              <%=GetPopUpScripts%>
            </head>
            <body bmi_id_max="${componentStateSelector.getComponentCount()}">
              ${iframeElements.reduce((acc, element) => acc.concat(element.outerHTML + "\n"), "")}
              <%=GetPopupDiv%>
            </body>
          </html>`;
}

function createIframeComponent(iframe: HTMLIFrameElement, counter: number): ComponentStateDto {
  return ComponentStateDto.createWithCounter(GENERIC_IFRAME, counter, TypeProvider.getInstance(), {
    view: {
      css: {
        top: iframe.style.top,
        left: iframe.style.left,
        order: iframe.style.order,
        position: iframe.style.position,
        padding: iframe.style.padding,
        margin: iframe.style.margin,
        border: resolveBorderStyleFromElement(iframe)
      },
      size: new ComponentCssSize(iframe.style.width, iframe.style.height),
      src: iframe.src,
      hostId: iframe.id,
      textAlignment: isEmpty(iframe.style.textAlign)
        ? TextAlignment.None
        : capitalizeText(iframe.style.textAlign),
      overflow: capitalizeText(iframe.style.overflow),
      dock: capitalizeText(iframe.style.float),
      clear: capitalizeText(iframe.style.clear),
      keepTogether: iframe.classList.contains(KEEP_TOGETHER_CLASS)
    } as DeepPartial<IframeViewConfig>
  });
}

export function extractIframeConfig(htmlText: string): Partial<IframeViewConfig> {
  const htmlElement = new DOMParser().parseFromString(htmlText, "text/html");
  const iframeElement = htmlElement.querySelector("iframe");
  if (isNotDefined(iframeElement)) {
    return {};
  }

  return {
    size: new ComponentCssSize(iframeElement.style.width, iframeElement.style.height),
    src: createDashboardsSrc(iframeElement.src)
  };
}

function createDashboardsSrc(originalSrc: string): string {
  if (!isDevMode()) {
    return originalSrc;
  }

  return originalSrc.replace(
    location.protocol + "//" + location.host,
    location.protocol + "//" + location.hostname + "/km"
  );
}

function createTemplateSrc(originalSrc: string): string {
  return isDevMode()
    ? originalSrc.replace(location.protocol + "//" + location.hostname + "/km", "..")
    : originalSrc.replace(location.protocol + "//" + location.host + "/km", "..");
}
