<div class="equipment-data-explorer__container">
  <div class="equipment-data-explorer__class">
    <span>{{ localizer.generalSettings.RootClass | translate }}</span>
    <div class="equipment-data-explorer__class-selector">
      <selector
        [items]="equipmentClasses"
        [selectedItem]="rootClass"
        (onChangeValue)="rootClassChanged$.next($event)"
        (keydown)="openHelp()"
      ></selector>
    </div>
  </div>
  <div class="browser__search input-wrapper">
    <div class="browser__search-input">
      <input
        class="browser__search-input-text"
        [value]="rootPath == '' ? '/' : rootPath"
        title="{{ localizer.generalSettings.RootPathTooltip | translate }}"
        readonly
      />
      <i
        class="abb-icon Close equipment-data-explorer__cross-icon"
        (click)="resetRootPath()"
        [hidden]="!fullEquipmentTree || rootPath == '' || rootPath == '/'"
      ></i>
      <i class="abb-icon abb-icon--medium Search" (click)="editRootPath()"></i>
    </div>
    <span class="input--error" [class.element--hidden]="!isRootPathInvalid">{{
      localizer.sidebar.InvalidRootPath | translate
    }}</span>
  </div>
  <equipment-browser
    *ngIf="!isRootPathInvalid"
    [rootPath]="rootPath"
    (selectedEquipmentChanged)="selectedEquipmentChanged($event)"
    (collapseSidebar)="collapseUnpinnedSidebarOnDragging()"
    [showTitle]="false"
    [openFullTree]="true"
  ></equipment-browser>
</div>
