import { AlignValue, Options, YAxisLabelsOptions, YAxisPlotLinesOptions } from "highcharts";
import { formatValueByStringFormat } from "../../../core/services/value-formatter.service";
import { Maybe, first } from "../../../ts-utils";
import { roundToTwoDecimals, tryConvertToNumber } from "../../../ts-utils/helpers/number.helper";
import { isDefined } from "../../../ts-utils/helpers/predicates.helper";
import {
  getTextColorForNoDataStatus,
  getTextColorForSingleValue
} from "../../helpers/color.helper";
import { getSingleValueFromConnector } from "../../helpers/component-data-accessor.helper";
import { DEFAULT_CHART_TEXT_COLOR_HEX } from "../../models/colors.constants";
import { DataStatus } from "../../models/data-status";
import { ILinearGaugeDisplayConfig } from "../../models/i-view-config/i-gauge-display-config";
import { DataConnectorDescriptor } from "../../models/store/data-connector-descriptor";
import { LimitMarkerHelper } from "../limit.helper";
import { PRIMARY_X_AXIS_ID, PRIMARY_Y_AXIS_ID } from "./base-highcharts-options.helper";
import { GaugeDisplayService } from "./gauge-display.service";

export abstract class LinearGaugeDisplayService extends GaugeDisplayService {
  constructor() {
    super();
    this.isTargetAsAxis = false;
  }

  protected getSpecificGaugeOptions(
    viewConfig: ILinearGaugeDisplayConfig,
    dataConnectorDescriptors: Maybe<DataConnectorDescriptor>[],
    dataStatus: DataStatus
  ): Options {
    const value = getSingleValueFromConnector(first(dataConnectorDescriptors)?.connector);
    const markerHelper = new LimitMarkerHelper(viewConfig.limits);
    const markers = markerHelper.getMarkers(0, 10);
    const min = Number(roundToTwoDecimals(tryConvertToNumber(viewConfig.min)));
    const max = Number(roundToTwoDecimals(tryConvertToNumber(viewConfig.max)));
    const { showLimitsAsBars } = viewConfig;

    const linearOptions: Options = {
      title: {
        text: getDisplayHTML(value, viewConfig),
        useHTML: true,
        verticalAlign: "bottom",
        style: {
          textTransform: "none"
        }
      },
      plotOptions: {
        series: {
          dataLabels: {
            enabled: false
          },
          stacking: "normal",
          pointWidth: getPointWidth(showLimitsAsBars, viewConfig.runtimeView.runtimeSize.widthInPx)
        } as any
      },
      xAxis: [
        {
          id: PRIMARY_X_AXIS_ID,
          visible: false,
          title: {
            text: null
          },
          labels: {
            enabled: false
          },
          gridLineWidth: 0,
          minorGridLineWidth: 0,
          minorTickLength: 0,
          tickLength: 0,
          ...getXAxisMinAndMax(showLimitsAsBars)
        }
      ],
      yAxis: [
        {
          id: PRIMARY_Y_AXIS_ID,
          title: {
            text: ""
          },
          labels: this.getYAxisLabelOptions(viewConfig),
          tickPositions: [min, max],
          minorTickInterval: null,
          gridLineWidth: 0,
          minorGridLineWidth: 0,
          minorTickLength: 0,
          tickLength: 0,
          opposite: this.getYAxisLabelAlignment(viewConfig),
          plotLines: [
            ...markers.map(
              (marker) =>
                ({
                  value: marker.value,
                  color: marker.color,
                  zIndex: marker.zIndex,
                  label: this.getPlotLineLabel(viewConfig, marker.value)
                } as YAxisPlotLinesOptions)
            )
          ],
          plotBands: [
            {
              id: PRIMARY_Y_AXIS_ID,
              from: min,
              to: max,
              color: "transparent",
              label: {
                useHTML: true,
                verticalAlign: "middle",
                textAlign: this.getTextAlignStyle(),
                align: "center",
                text: dataStatus !== DataStatus.DataReceived ? dataStatus : "",
                y: this.getYOffset(),
                style: {
                  color: getTextColorForNoDataStatus(dataStatus, DEFAULT_CHART_TEXT_COLOR_HEX),
                  fontSize: "12px",
                  fontWeight: "600",
                  fill: "#666666",
                  fontFamily: "ABB Font"
                }
              }
            },
            ...(showLimitsAsBars ? this.getYAxisPlotBands(viewConfig) : [])
          ]
        }
      ],
      tooltip: {
        enabled: false
      },
      series: []
    };

    return linearOptions;
  }

  protected getYAxisLabelOptions(viewConfig: ILinearGaugeDisplayConfig): YAxisLabelsOptions {
    return {
      enabled: viewConfig.showYAxisLabels,
      x: 0,
      y: 0
    };
  }

  protected getYAxisLabelAlignment(_viewConfig: ILinearGaugeDisplayConfig): boolean {
    return true;
  }

  protected abstract getYOffset(): number;
  protected abstract getTextAlignStyle(): AlignValue;
  protected abstract getPlotLineLabel(viewConfig: ILinearGaugeDisplayConfig, value: number);
}

function getDisplayHTML(value: number, viewConfig: ILinearGaugeDisplayConfig): string {
  const titleHTML = viewConfig.showTitle ? getStyledTitleHTML(viewConfig) : "";
  const valueHTML = viewConfig.shouldShowDataLabel ? getStyledValueHTML(viewConfig, value) : "";
  return `
    <div>
      ${titleHTML}
      ${valueHTML}
    <div>
  `.trim();
}

function getStyledTitleHTML(viewConfig: ILinearGaugeDisplayConfig): string {
  return `<div>${viewConfig.title ?? ""}</div>`;
}

function getStyledValueHTML(viewConfig: ILinearGaugeDisplayConfig, value: number): string {
  const shouldTakeFixedFont = viewConfig.fontSize != null;
  const fontSize: string = shouldTakeFixedFont ? `${viewConfig.fontSize}px` : "inherit";
  const textColor: string = getTextColorForSingleValue(value, viewConfig);
  return `
    <div style="
      color: ${textColor};
      font-size: ${fontSize};
      ">
      ${isDefined(value) ? formatValueByStringFormat(value, viewConfig.displayFormat) : ""} ${
    viewConfig.unit || ""
  }
    </div>
  `.trim();
}

function getPointWidth(showLimitsAsBars: boolean, width: number): Maybe<number> {
  return showLimitsAsBars ? width * 2 : undefined;
}

function getXAxisMinAndMax(showLimitsAsBars: boolean) {
  const xAxisLeftOffset: number = -0.2;
  const xAxisRightOffset: number = 0.1;
  return showLimitsAsBars
    ? {
        min: xAxisLeftOffset,
        max: xAxisRightOffset
      }
    : { min: undefined, max: undefined };
}
