import { HttpClient } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { encodeQueryString } from "projects/ui-core/src/lib/browsing/services/link-resolver";
import { Observable, Subject } from "rxjs";
import { filter, tap } from "rxjs/operators";
import {
  QueryStringService,
  WebServicesConfiguration
} from "ui-core";
import { Dashboard, DashboardNode } from "../../models/side-nav/sidebar/dashboard";
import { DashboardChange } from "../../models/side-nav/sidebar/dashboard-change";
import { DashboardChangeType } from "../../models/side-nav/sidebar/dashboard-change-type";
import { ValidationResult } from "../../models/side-nav/sidebar/validation-result";
import { DashboardValueValidatorService } from "./dashboard-value-validator.service";

/**
 * #TODO Magic Number. Approximately 1250 milliseconds it takes for JSON server to save and restart a report.
 */
export const JSON_UPLOAD_TIME = 1250;

@Injectable()
export class DashboardNavigationService implements OnDestroy {
  /**
   * Calls all dashboard elements to close their tooltip.
   */
  public onTooltipClose: Subject<boolean> = new Subject();
  /**
   * Emits an event after a Sidebar Form Element has been closed.
   */
  public onFormClosed: Subject<boolean> = new Subject();
  public dashboardsUpdateStarting$ = new Subject<Dashboard[]>();
  /**
   * Listens to any dashboards array changes.
   */
  public dashboardsUpdate$ = new Subject<Dashboard[]>();
  /**
   * Current collection of dashboards.
   */
  public dashboards: Dashboard[] = [];
  /**
   * Emits an event after going to Customize Mode.
   */
  public isCustomizeMode: Subject<boolean> = new Subject();

  unsubscribeSubject$ = new Subject();

  private currentRootPath: string | undefined;
  private customer: string | undefined;

  get currentCustomer(): string | undefined {
    return this.currentRootPath?.split("/")[0];
  }

  get currentMotor(): string | undefined {
    return this.currentRootPath?.split("/")[2];
  }

  constructor(
    private apiConfig: WebServicesConfiguration,
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private validator: DashboardValueValidatorService,
    private queryStringService: QueryStringService,
  ) {
    this.router.events.
      pipe((
        filter(event => event instanceof NavigationEnd),
        tap(() => this.currentRootPath = this.route.snapshot.queryParams["rootPath"])
      ))
      .subscribe()
  }

  /**
   * Remove the report, which in fact will only move it to 'delete' directory.
   */
  public deleteReport$(report: string): Observable<object> {
    return this.http.delete(`${this.apiConfig.reportsUrl}/${this.currentCustomer}/reports/${report}`);
  }

  public renameReport$(details: DashboardNode): Observable<Dashboard>{
    return this.http.put<Dashboard>(`${this.apiConfig.reportsUrl}/${this.currentCustomer}/reports/${details.link}/rename`, details);
  }

  /**
   * Flatten the dashboard before updating the configuration by removing children's array.
   */
  public updateReportTree$(dashboards: DashboardNode[]): Observable<DashboardNode[]> {
    this.dashboards = dashboards;
    const responseBody = {
      project: this.currentCustomer,
      dashboards: dashboards
    };

    return this.http.put<Dashboard[]>(
      `${this.apiConfig.reportsUrl}/${this.currentCustomer}/reports/layout/details`,
      responseBody
    );
  }

  /**
   * Update the dashboard based on user's change.
   */
  public updateDashboard(changes: DashboardChange): void {
    this.dashboardsUpdateStarting$.next();
    switch (changes.type) {
      case DashboardChangeType.Renamed:
        this.onDashboardTitleChanged(changes);
        break;
      case DashboardChangeType.SelectedMain:
        this.onDashboardFavoriteChanged(changes);
        break;
      case DashboardChangeType.Deleted:
        this.onDashboardDelete(changes);
        break;
    }
  }

  /**
   * Validate the link of a dashboard.
   */
  public checkLink(link: string): ValidationResult {
    return this.validator.checkLink(link, this.dashboards);
  }

  /**
   * Validate the title of a dashboard.
   */
  public checkTitle(title: string): ValidationResult {
    return this.validator.checkTitle(title, this.dashboards);
  }

  /**
   * Emit an event to every `DashboardNavigationElement` to close its tooltip.
   */
  public closeTooltips(): void {
    this.onTooltipClose.next();
  }

  /**
   * Whether the current dashboard is open
   */
  public isDashboardActive(name: string): boolean {
    return (
      this.router.url.split("/")[2].split("?")[0].toLocaleLowerCase() === name.toLocaleLowerCase()
    );
  }

  /**
   * Rename title of the dashboard and update the children's parent reference.
   */
  private onDashboardTitleChanged(changes: DashboardChange): void {
    this.dashboards = changes.dashboards;
    const d = this.dashboards.find((d1) => d1.link === changes.previous?.link);

    if (d) {
      d.title = changes.current.title;
      this.renameReport$(d)
        .pipe(tap(() => this.dashboardsUpdate$.next(this.dashboards)))
        .subscribe();
    }
  }

  /**
   * Change currently favorite dashboard.
   */
  private onDashboardFavoriteChanged(changes: DashboardChange): void {
    this.dashboards = changes.dashboards;
    this.dashboards.forEach((dashboard) => {
      dashboard.isMain = dashboard.link === changes.current.link;
    });

    this.dashboardsUpdate$.next(this.dashboards);
  }

  /**
   * Remove the dashboard from an array, change the current location if user removed current report.
   */
  private onDashboardDelete(changes: DashboardChange): void {
    const currentReportLink = "";
    this.dashboards = changes.dashboards;

    const childIsSelected = changes.current.children?.find(
      (report) => report.link === currentReportLink
    );

    this.deleteReport$(changes.current.link).pipe(
      tap(() => {
          const params = this.queryStringService.getParams();
          const queryString = params.toString();
          const encodedString = encodeQueryString(queryString);

          if (changes.current.link === currentReportLink || childIsSelected) {
            this.redirectToFirstReport(encodedString);
          }
          this.dashboardsUpdate$.next();
      })).subscribe();
  }

  redirectToFirstReport(encodedString: string): void {
    const mainDashboard = this.dashboards.find((dashboard: Dashboard) => dashboard.isMain);
    const firstReport = `${this.dashboards[0].link}?${encodedString}`;
    const navigationLink = mainDashboard ? mainDashboard : firstReport;
    this.router.navigateByUrl(`/report/${navigationLink}`);
  }

  ngOnDestroy(): void {
    this.unsubscribeSubject$.complete();
  }
}
