import { Provider, Type } from "@angular/core";
import { UpgradeStep, UPGRADE_STEP } from "../models/upgrade-step";
import { AddCardPositioningType } from "./add-card-positioning-type";
import { AddFilterEntityToConnector } from "./add-filter-entity-to-connector";
import { CreateComponentStateView } from "./create-compoonent-state-view";
import { RelocateDataConnectorViewProperties } from "./relocate-data-connector-view-properties";
import { RelocateLimits } from "./relocate-limits";
import { RelocateOrderFromTableConnectorView } from "./relocate-order-from-table-connector-view";
import { RelocatePeriodType } from "./relocate-period-type";
import { RelocateStandardFilters } from "./relocate-standard-filters";
import { RelocateAxisProperties } from "./relocate-y-axis-properties";
import { RemoveColumnIdInConnectors } from "./remove-columnId-in-connectors";
import { RemoveDefaultConnectorTitleAndSignalName } from "./remove-default-connector-title-and-signal-name";
import { RemoveObsoleteCardProps } from "./remove-obsolete-card-props";
import { RemovePositionStatic } from "./remove-position-static";
import { RenameAggregationFunctions } from "./rename-aggregation-functions";
import { RenameBasicCardView } from "./rename-basic-card-view";
import { RenameClusterProperty } from "./rename-cluster-property";
import { RenameDisplayStrategies } from "./rename-display-strategies";
import { ReplaceGlobalIdWithRuntimeIdFilter } from "./replace-global-with-runtime-filter";
import { UpdateArrayAggregationParam } from "./update-array-aggregation-param";
import { UpdateDataAggregationConfig } from "./update-data-aggregation-config";
import { UpdateDefaultLabelFontSize } from "./update-default-label-font-size";
import { UpdateLinks } from "./update-links";
import { UpdateTypeProps } from "./update-type-props";
import { UpgradeCardSize } from "./upgrade-card-size";
import { UpgradeComponentSize } from "./upgrade-component-size";
import { UpgradeCssBorder } from "./upgrade-css-border";
import { UpgradeFullyCardSize } from "./upgrade-fully-card-size";
import { UpgradeMinMaxValues } from "./upgrade-min-max-values";
import { UpgradeWidgetSize } from "./upgrade-widget-size";
import { UpgradeWidgetInterpolablePropertiesDefaults } from "./upgrade-widget-title";

// Remember: When adding upgrade steps, ensure that KBST import/export still works.
// In cPIMS\KBS.KM\WS\ItemsAPI\GenericDataSourceTest\KbstImportExport
// create new version of the report.json files and check that unit-tests still succeed.
export const allUpgradeSteps: Type<UpgradeStep>[] = [
  CreateComponentStateView,
  RenameDisplayStrategies,
  RemovePositionStatic,
  RenameAggregationFunctions,
  UpgradeCardSize,
  UpdateLinks,
  UpdateTypeProps,
  UpdateDefaultLabelFontSize,
  UpdateDataAggregationConfig,
  RenameBasicCardView,
  RelocatePeriodType,
  UpdateArrayAggregationParam,
  AddCardPositioningType,
  RemoveObsoleteCardProps,
  RelocateLimits,
  RelocateDataConnectorViewProperties,
  UpgradeWidgetSize,
  RelocateAxisProperties,
  ReplaceGlobalIdWithRuntimeIdFilter,
  UpgradeWidgetInterpolablePropertiesDefaults,
  RenameClusterProperty,
  UpgradeComponentSize,
  AddFilterEntityToConnector,
  RelocateStandardFilters,
  UpgradeCssBorder,
  RemoveColumnIdInConnectors,
  RelocateOrderFromTableConnectorView,
  UpgradeFullyCardSize,
  UpgradeMinMaxValues,
  RemoveDefaultConnectorTitleAndSignalName
];

export const allUpgradeStepProviders: Provider[] = allUpgradeSteps.map((step) => {
  return {
    provide: UPGRADE_STEP,
    useClass: step,
    multi: true
  };
});
