import { v4 as uuid } from "uuid";
import { DataTransferObject } from "../../../core/models/data-transfer-object";
import { construct } from "../../../core/services/construct.helper";
import { LOCALIZATION_DICTIONARY } from "../../../i18n/models/localization-dictionary";
import {
  AllowInterpolation,
  Configurable,
  EditorType,
  Key,
  Serializable,
  Title
} from "../../../meta";
import { EditableType } from "../../../meta/decorators/editable-type.decorator";
import { DeepPartial } from "../../../ts-utils/models/deep-partial.type";

const TYPE_NAME = "TableHeaderDescriptor";

@EditableType({ fullName: TYPE_NAME, title: "table-header-descriptor" })
export class TableHeaderDescriptor implements DataTransferObject {
  typeName: string = TYPE_NAME;

  @Key()
  @Serializable()
  id!: string;

  @Serializable("")
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Title,
    editorType: EditorType.TextBox,
    tooltipKey: LOCALIZATION_DICTIONARY.propertySheet.HeaderAndFooterLabelTooltip
  })
  @Title()
  label!: string;

  @Serializable()
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Template,
    editorType: EditorType.TextBox,
    tooltipKey: LOCALIZATION_DICTIONARY.propertySheet.HeaderTemplateTooltip
  })
  @AllowInterpolation()
  template!: string;

  constructor(header: DeepPartial<TableHeaderDescriptor> = {}) {
    construct(this, header, TYPE_NAME, { id: uuid() });
  }
}
