import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  QueryList,
  ViewChildren
} from "@angular/core";
import { cloneDeep as _cloneDeep } from "lodash";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import {
  EventSubscription,
  WidgetEventType
} from "../../../core/models/widget-eventing/widget-event";
import { CROSS_WIDGET_EVENTING } from "../../../elements/models/help-constants";
import { LinkingWidgetService } from "../../../elements/services/linking-widget.service";
import { LocalizationService } from "../../../i18n/localization.service";
import { OfType } from "../../../meta/decorators/of-type.decorator";
import { EditorType } from "../../../meta/models/editor-type";
import { EntityId } from "../../../meta/models/entity";
import { TypeProvider } from "../../../meta/services/type-provider";
import { BaseEditorComponent } from "../base-editor.component";

@Component({
  selector: "link-widget-editor",
  templateUrl: "link-widget-editor.component.html",
  styleUrls: ["link-widget-editor.component.scss"]
})
@OfType(EditorType.LinkWidget)
export class LinkWidgetEditorComponent extends BaseEditorComponent implements OnDestroy {
  links: EventSubscription[] = [];
  isLinkingModeActive = false;
  private lastChangedLinkIndex!: number;
  private unsubscribeSubject$: Subject<any> = new Subject<any>();

  @ViewChildren("linkWrapper")
  linkWrappers?: QueryList<ElementRef>;

  constructor(
    protected cdr: ChangeDetectorRef,
    protected typeProvider: TypeProvider,
    protected translationService: LocalizationService,
    protected linkingWidgetService: LinkingWidgetService
  ) {
    super(cdr, typeProvider, translationService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.links = this.value;
    this.subscribeToLinkingWidget();
  }

  ngOnDestroy(): void {
    this.unsubscribeSubject$.next();
    this.unsubscribeSubject$.complete();
  }

  private subscribeToLinkingWidget(): void {
    this.linkingWidgetService.widgetLinked$
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((widgetId) => {
        this.links = _cloneDeep(this.value);
        this.links.push({
          eventType: WidgetEventType.SelectTimeRangeEvent,
          sourceWidgetId: widgetId.toString()
        });
        super.onValueChanged(this.links);
      });
    this.linkingWidgetService.linkingModeChanged$
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((isActive) => (this.isLinkingModeActive = isActive));
  }

  linkWidget(): void {
    this.linkingWidgetService.activateLinkingMode();
  }

  unlinkWidget(index: number): void {
    this.links = _cloneDeep(this.value);
    this.unhighlightSourceWidget(this.links[index].sourceWidgetId as EntityId);
    this.links.splice(index, 1);
    super.onValueChanged(this.links);
  }

  refreshValue(value): void {
    this.lastChangedLinkIndex = value.findIndex(
      (link, i) => JSON.stringify(link) !== JSON.stringify(this.value[i])
    );
    super.refreshValue(value);
  }

  focus(): void {
    const linkWrapper = this.linkWrappers?.get(this.lastChangedLinkIndex)?.nativeElement;
    linkWrapper?.focus();
  }

  highlightSourceWidget(widgetId: EntityId): void {
    this.linkingWidgetService.highlightSourceWidget(widgetId);
  }

  unhighlightSourceWidget(widgetId: EntityId): void {
    this.linkingWidgetService.unhighlightSourceWidget(widgetId);
  }

  openHelp(): void {
    window.top?.open(CROSS_WIDGET_EVENTING);
  }
}
